export const renderWithPaging = <T,>(blobItems: T[], page: number, itemsInAPage: number): T[] => {
  
  let currentBlobItems: T[] = [];

  if (page === 1) {
    currentBlobItems = blobItems.slice(0, itemsInAPage);
  } else {
    const pageItemStartNumber: number = (page - 1) * itemsInAPage;
    const areWelastPage =
      blobItems.length - pageItemStartNumber < itemsInAPage;
    if (areWelastPage) {
      currentBlobItems = blobItems.slice(pageItemStartNumber);
    } else {
      currentBlobItems = blobItems.slice(
        pageItemStartNumber,
        pageItemStartNumber + itemsInAPage
      );
    }
  }
  return currentBlobItems;
};



