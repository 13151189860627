export const ProcessOpen = () => {
  return (
    <>
      <span className=" d-none d-sm-block">Show appeal process </span>
      <span className=" d-block d-sm-none">Show process </span>
      <i className="bi bi-chevron-down"></i>
    </>
  );
};

export default ProcessOpen;
