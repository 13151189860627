import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { LtcExcelExportProps, LtcHearing } from '@scts/models';
import { PrimaryButton } from '@scts-ui/component-library';

const ExcelExport = (props: LtcExcelExportProps) => {

  const createFileName = (prefix: string) => {
    const currentDateTime: string = new Date().toLocaleString();
    let dateToFileName: string = currentDateTime
                                  .replace(/\//g, "")
                                  .replace(/,/g, "_")
                                  .replace(/ /g, "")
                                  .replace(/:/g, "");
    dateToFileName = dateToFileName.substring(0, 4) + dateToFileName.substring(6, dateToFileName.length);
    return(prefix + "_" + dateToFileName);
  };

  const exportToExcel = () => {
    //Reformat date string in the hearingDateAndTime property
    let data: LtcHearing[] = props.data;
    data = data.map((hearing) => { 
      const formattedDate = new Date(hearing.hearingDateAndTime).toLocaleString();
      hearing.hearingDateAndTime = formattedDate.substring(0, 17);
      return hearing;
    });

    //Create worksheet object from the data
    const worksheet = XLSX.utils.json_to_sheet(data);

    //If it is a hearing table, change the column names to content of array below from the property names of LtcHearings type which is camelcase
    if(props.fileName === "hearings") {
      XLSX.utils.sheet_add_aoa(worksheet, [[
        "Hearing Date And Time",
        "Hearing Type",	
        "Venue",	
        "Reference",	
        "Appeal Type",	
        "Appeal Subtype",	
        "Appellant",	
        "Representative",	
        "Respondent",	
        "Address",	
        "Assessor Reference",	
        "Property Type",	
        "Net Annual Value",	
        "Rateable Value",	
        "Effective Date",	
        "Appeal Date",	
        "Appeal Reason"
      ]], { origin: "A1" });
    }

    //Setting the width of the table columns
    const nrOfColumns = 17;
    if ( !worksheet["!cols"] ) {
      worksheet["!cols"] = [];
    }
    
    for(let i = 0; i < nrOfColumns; i++ ) {
      if ( i === 2 ) {
        worksheet["!cols"][i] = {wch: 52};
      }
      else if ( i === 9 )
      {
        worksheet["!cols"][i] = {wch: 30};
      } 
      else 
      {
        worksheet["!cols"][i] = {wch: 22};
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
    saveAs(blob, `${createFileName(props.fileName)}.xlsx`);
  };

  return (
    <PrimaryButton onClick={exportToExcel} buttonText={'Download Table'} />
  );
}

export default ExcelExport;