import { LtcMembers } from '@scts/app-data';
import { PageHeader, PageHeaderSg } from '@scts-ui/component-library';
import { MemberRole } from '@scts/models';
import { useTitleService } from '@scts/utilities';

export const About = () => {
  useTitleService('About Page: Local Taxation Chamber');
  const members: MemberRole[] = LtcMembers;

  return (
    <>
      <PageHeaderSg headerText={'About Local Taxation Chamber'} />

      <PageHeader icon={''} detail={'Our Role'} />
      <p>
        The tribunal hears and determines appeals that are made by ratepayers
        and council tax payers in relation to Non-Domestic Rates, Council Tax,
        Water Charges, Council Tax Reduction and Penalty Notices.
      </p>
      <p>
        {' '}
        The functions of the existing Valuation Appeal Panels and the Council
        Tax Reduction Review Panel (CTRRP) will transfer to the Chamber on 1st
        April 2023.
      </p>

      <PageHeader icon={''} detail={'Members of the Local Taxation Chamber'} />
      <p>The Chamber President is Jacqui Taylor.</p>

      <p>
        The term Chamber when used in our guidance and on this website refers to
        the organisation itself, its administration and its judiciary. The
        Chamber administration is carried out by staff of the Scottish Courts
        and Tribunals Service.
      </p>

      <p>
        Members of the Chamber comprise legal members, ordinary members (with
        surveyor rating experience) and ordinary members (with valuation
        experience). When they consider and decide a case they are referred to
        as a tribunal.
      </p>

      <p>
        Under the First-tier Tribunal for Scotland Local Taxation Chamber and
        Upper Tribunal for Scotland (Composition) Regulations 2023, a tribunal
        may comprise the Chamber President or legal member and either one or two
        ordinary members. In the case of a council tax non list (liability case)
        and council tax reduction appeal the tribunal may consist of a legal
        member alone.
      </p>
      <div className="table-responsive">
        <table className="ds_table">
          <caption>Local Taxation Chamber Members</caption>

          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">Forename</th>
              <th scope="col">Surname</th>
              <th scope="col">Member Type</th>
            </tr>
          </thead>
          <tbody>
            {members &&
              members.map((member, index) => (
                <tr key={index}>
                  <td>{member.title}</td>
                  <td>{member.firstName}</td>
                  <td>{member.lastName}</td>
                  <td>{member.type}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default About;
