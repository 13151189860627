import { AppDataLocalTax } from '@scts/app-data';
import { PageHeaderSg } from '@scts-ui/component-library';
import { useTitleService } from '@scts/utilities';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const Legislation = () => {
  useTitleService('Legislation: Local Taxation Chamber');
  const loc = useLocation();

  useEffect(() => {
    const elemId = loc.hash.replace('#', '');
    if (loc.hash !== '') {
      document?.getElementById(elemId)?.scrollIntoView({
        behavior: 'smooth',
      });
      loc.hash = '';
    }
  });

  return (
    <>
      <PageHeaderSg headerText="Legislation" />
      <p>Please click the links to view the particular piece of legislation </p>

      {AppDataLocalTax.legislation.items.map((item, index) => (
        <div key={index}>
          <h2>{item.title}</h2>
          <ul>
            {item.links.map((link, index) => (
              <li key={index}>
                <a target="_blank" href={link.url} rel="noreferrer">
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

export default Legislation;
