import { FileBlob, LtcHearing } from "@scts/models";
import { SelectOptionProps } from '@scts-ui/component-library';

const filterCurrentArray = <T,>(currentFilterArray: T[], isDate?: boolean) => {
  
  const filterArray: SelectOptionProps[] = [];
  let currentArrayKey = 1;

  const setInAbcOrder = (filterArray: SelectOptionProps[]) => {
    filterArray.sort((a, b) => {
      return a.value.toUpperCase() > b.value.toUpperCase() ? 1 : -1;
    });
  };
  const addOptionAll = (filterArray: SelectOptionProps[]) => {
    if (filterArray.length > 1) filterArray.unshift({ 'key': '0', 'value': 'All' });
  };

  if (isDate) {
    const uniques = new Set(currentFilterArray);
    uniques.forEach(currentItem => {
      filterArray.push({
        key: currentArrayKey.toString(),
        value: String(currentItem)
      });
      currentArrayKey++;
    });
  } else {
    currentFilterArray.forEach(currentItem => {
      filterArray.push({
        key: currentArrayKey.toString(),
        value: String(currentItem)
      });
      currentArrayKey++;
    });
    setInAbcOrder(filterArray);
  }
  
  addOptionAll(filterArray);
  return filterArray;
};

export const creatingFilterArraysForDecisons = (blobItemsArray: FileBlob[]) => {

  const currentDecisions = [...new Map(blobItemsArray.map((item: FileBlob) => [item['meta']['DecisionType'], item['meta']['DecisionType']])).values()];
  const currentAppealTypes = [...new Map(blobItemsArray.map((item: FileBlob) => [item['meta']['AppealType'], item['meta']['AppealType']])).values()];
  const currentAppealSubTypes = [...new Map(blobItemsArray.map((item: FileBlob) => [item['meta']['AppealSubType'], item['meta']['AppealSubType']])).values()];
  const currentRespondents = [...new Map(blobItemsArray.map((item: FileBlob) => [item['meta']['Respondent'], item['meta']['Respondent']])).values()];
  
  return [
    filterCurrentArray(currentAppealTypes), 
    filterCurrentArray(currentAppealSubTypes), 
    filterCurrentArray(currentDecisions), 
    filterCurrentArray(currentRespondents)
  ];
};

export const creatingFilterArraysForHearings = (hearingsArray: LtcHearing[]) => {

  const currentDates = [...new Map(hearingsArray.map((item: LtcHearing) => [item['hearingDateAndTime'], new Date(item['hearingDateAndTime']).toLocaleDateString()])).values()];
  const currentAppealTypes = [...new Map(hearingsArray.map((item: LtcHearing) => [item['appealType'], item['appealType']])).values()];
  const currentRespondents = [...new Map(hearingsArray.map((item: LtcHearing) => [item['respondent'], item['respondent']])).values()];

  return [
    filterCurrentArray(currentDates, true), 
    filterCurrentArray(currentAppealTypes), 
    filterCurrentArray(currentRespondents)
  ];
};

export const filterDecisions = (
  blobItemsArray: FileBlob[],
  decisionFilter: string,
  appealTypeFilter: string,
  appealSubTypeFilter: string,
  respondentFilter: string) => {

  let currentBlobItemsArray: FileBlob[] = blobItemsArray;

  if (decisionFilter !== 'All') {
    currentBlobItemsArray = currentBlobItemsArray.filter(blob => blob.meta.DecisionType?.includes(decisionFilter));
  }
  if (appealTypeFilter !== 'All') {
    currentBlobItemsArray = currentBlobItemsArray.filter(blob => blob.meta?.AppealType?.includes(appealTypeFilter));
  }
  if (appealSubTypeFilter !== 'All') {
    currentBlobItemsArray = currentBlobItemsArray.filter(blob => blob.meta?.AppealSubType?.includes(appealSubTypeFilter));
  }
  if (respondentFilter !== 'All') {
    currentBlobItemsArray = currentBlobItemsArray.filter(blob => blob.meta?.Respondent?.includes(respondentFilter));
  }

  return currentBlobItemsArray;
};

export const filterHearings = (
  hearingsArray: LtcHearing[],
  dateFilter: string,
  appealTypeFilter: string,
  respondentFilter: string) => {

  let currentHearingsArray: LtcHearing[] = hearingsArray;

  if (dateFilter !== 'All') {
    currentHearingsArray = currentHearingsArray.filter((hearing: LtcHearing) => new Date(hearing.hearingDateAndTime).toLocaleDateString().includes(dateFilter));
  }
  if (appealTypeFilter !== 'All') {
    currentHearingsArray = currentHearingsArray.filter(hearing => hearing.appealType.includes(appealTypeFilter));
  }
  if (respondentFilter !== 'All') {
    currentHearingsArray = currentHearingsArray.filter(hearing => hearing.respondent.includes(respondentFilter));
  }

  return currentHearingsArray;
};