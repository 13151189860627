import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import '../node_modules/@scottish-government/pattern-library/dist/css/pattern-library.css';
import '../node_modules/@scts-ui/component-library/dist/index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './index.scss';
import { createRoot } from 'react-dom/client';
import App from './app/app';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000, //5 mins stale time
      refetchOnWindowFocus: false,
    },
  },
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </StrictMode>
);
