import { ColumnDef } from '@tanstack/react-table';
import { FileBlob } from '@scts/models';

export const DecisonsParentColumns: ColumnDef<FileBlob>[] = [
  {
    id: 'expander',
    header: () => <span className='visually-hidden'>Toggle expand button to expand or close table rows</span>,
    cell: ({ row }) => {
      return row.getCanExpand() ? (
        <button
          {...{
            onClick: row.getToggleExpandedHandler(),
            style: { cursor: 'pointer', border: 'none', backgroundColor: 'transparent' },
            title:'Expand row button',
            'aria-label': 'Toggle to expand row for more information, or close it',
          }}
        >
          {row.getIsExpanded() ? 
          <span className="ds_accordion-item__indicator" 
            style={{
              height: '15px', 
              width: '15px', 
              position:'static', 
              color: '#0065bd',
              transition:'.3s'
          }}/> 
          : <span className="ds_accordion-item__indicator ds_accordion-item__indicator-clicked" 
          style={{
            height: '15px', 
            width: '15px', 
            position:'static', 
            color: '#0065bd',
            transition:'.3s',
            rotate: '-90deg'
          }}/>
        }
        </button>
      ) : (
        '🔵'
      )
    },
  },
  {
    accessorFn: row => `${row.meta.Reference ? row.meta.Reference : 'N/A'}`,
    header: 'Case Reference',
    cell: info => info.getValue(),
    footer: info => info.column.id,
  },
  {
    accessorFn: row => `${row.meta.DecisionType ? row.meta.DecisionType : 'N/A'}`,
    header: 'Decision',
    cell: info => info.getValue(),
    footer: info => info.column.id,
  },
  {
    accessorFn: row => `${row.meta.Appellant ? row.meta.Appellant : 'N/A'}`,
    header: 'Appellant',
    cell: info => info.getValue(),
    footer: info => info.column.id,
  },
  {
    accessorFn: row => `${row.meta.Respondent ? row.meta.Respondent : 'N/A'}`,
    header: 'Respondent',
    cell: info => info.renderValue(),
    footer: info => info.column.id,
  },
  {
    accessorFn: row => `${row.meta.DecisionDate ? row.meta.DecisionDate : 'N/A'}`,
    header: 'Date',
    cell: info => info.renderValue(),
    footer: info => info.column.id,
  },
  {
    accessorFn: row => {
      return(
        <a href={row.url} target="_blank" rel="noreferrer">
          <span className="visually-hidden">download {row.name}</span>
          <i className="bi bi-download fs-5"></i>
        </a>
      )
    },
    header: 'Download',
    cell: info => info.renderValue(),
    footer: info => info.column.id,
  }
];