import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { FileBlob, Meta } from '@scts/models';
import { blobToString } from './blob-to-string';

//we will have a FD url in PP and PA, but not in DV & TS
export const getContainer = (
  accountName: string,
  containerName: string,
  url: string
): ContainerClient => {
  //if no front door then we use real blob address for dv/ts, otherwise we use the FD url
  if (url === '') {
    url = `https://${accountName}.blob.core.windows.net/`;
  }

  const blobService = new BlobServiceClient(url);

  // can't actually catch an error here
  const containerClient: ContainerClient =
    blobService.getContainerClient(containerName);

  return containerClient;
};

export const getJsonBlob = async (
  containerClient: ContainerClient,
  fileName: string
): Promise<string> => {
  const jsonFile = await containerClient.getBlobClient(fileName).download();

  let downloaded = '';
  if (jsonFile.blobBody)
    downloaded = await blobToString(await jsonFile.blobBody);

  return downloaded;
};

export const getBlobs = async (
  containerClient: ContainerClient
): Promise<FileBlob[]> => {
  const blobItems: FileBlob[] = [];

  for await (const fileBlob of containerClient.listBlobsFlat({
    includeMetadata: true,
  })) {
    const newBlob: FileBlob = {
      name: fileBlob.name,
      url: '',
      meta: {
        Appellant: '',
        DecisionDate: '',
        DecisionType: '',
        Filename: '',
        Reference: '',
        Respondent: '',
        AppealType: '',
        AppealSubType: '',
        AppellantRepresentative: '',
      },
      DecisionDate: new Date(),
    };

    newBlob.url = containerClient.getBlobClient(fileBlob.name).url;

    newBlob.meta = fileBlob.metadata as unknown as Meta;
    const theDate = newBlob.meta.DecisionDate;
    newBlob.DecisionDate =
      theDate && (theDate.toLowerCase() !== 'unknown' || theDate !== '')
        ? new Date(
            +theDate.split('/')[2],
            +theDate.split('/')[1] - 1,
            +theDate.split('/')[0]
          )
        : new Date(2020, 0, 1);

    blobItems.push(newBlob);
  }
  return blobItems;
};
