import {
  ErrorSimple,
  Loader,
  PageHeaderSg,
  PagingSg,
  PhaseBanner,
  SelectComponent,
  PrimaryButton,
  SearchAsYouTypeComponent,
} from '@scts-ui/component-library';
import { FileBlob } from '@scts/models';
import { useEffect, useState } from 'react';
import { getContainer, getBlobs } from '../../utils/decisions-utils';
import { useConfigLoader } from '@scts/utilities';
import { useTitleService } from '@scts/utilities';
import DecisionsTable, { renderSubComponent } from './decisions-table';
import { DecisonsParentColumns } from './decisions-columns';
import { SelectOptionProps } from '@scts-ui/component-library';
import { creatingFilterArraysForDecisons } from '../../utils/filter';
import { filterAndSearchDecisions } from '../../utils/combinedFiltersAndSearch';
import {renderWithPaging} from '../../utils/paging';


export const Decisions = () => {
  const config = useConfigLoader();
  useTitleService('Tribunal Decisions: Local Taxation Chamber');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [blobItems, setBlobItems] = useState<FileBlob[]>([]);
  const [currentBlobItems, setCurrentBlobItems] = useState<FileBlob[]>([]);
  const [filteredBlobItems, setFilteredBlobItems] = useState<FileBlob[]>([]);
  const [noBlobs, setNoBlobs] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsInAPage = 8;
  //filter arrays
  const [appealTypes, setAppealTypes] = useState<SelectOptionProps[]>([]);
  const [appealSubTypes, setAppealSubTypes] = useState<SelectOptionProps[]>([]);
  const [decisions, setDecisions] = useState<SelectOptionProps[]>([]);
  const [respondents, setRespondents] = useState<SelectOptionProps[]>([]);
  //active filter item
  const [appealTypeFilter, setAppealTypeFilter] = useState<string>('All');
  const [appealSubTypeFilter, setAppealSubTypeFilter] = useState<string>('All');
  const [decisionFilter, setDecisionFilter] = useState<string>('All');
  const [respondentFilter, setRespondentFilter] = useState<string>('All');

  const addValueWhenAppealReasonIsUndefined = (blobs: FileBlob[]) => {
    blobs.forEach((blob) => {
      if (!blob.meta.AppealReason) {
        if (blob.meta.AppealType?.toLowerCase() === 'non-domestic rates') {
          blob.meta.AppealReason = 'UNKNOWN';
        } else {
          blob.meta.AppealReason = 'N/A';
        }
      }
    });
  };

  useEffect(() => {
    if (config.data) {
      const getStorageblobs = async () => {
        const cont = getContainer(
          config.data.storageAccount,
          config.data.documentsFolder,
          config.data.frontDoorUrl
        );
        try {
          setIsFetchingData(true);
          const blobs = await getBlobs(cont);

          if (blobs.length === 0) {
            setNoBlobs(true);
          } 
          else {
            addValueWhenAppealReasonIsUndefined(blobs);         
            setBlobItems(blobs);
          }
        } 
        catch (error) {
          setNetworkError(true);
        }
        finally{
          setIsFetchingData(false);
        }
      };

      getStorageblobs();
    }
  }, [config.data]);

  useEffect(() => {
    if (blobItems.length > 0) {
      blobItems.sort((objA, objB) => {
        return (
          new Date(objB.DecisionDate).getTime() -
          new Date(objA.DecisionDate).getTime()
        );
      });
      childPageClicked(1);
      setNoBlobs(false);
    }
  }, [blobItems]);

  useEffect(() => {
    if (blobItems.length > 0) {
      const filteredAndSearchedDecisions: FileBlob[] = filterAndSearchDecisions(blobItems, decisionFilter, appealTypeFilter, appealSubTypeFilter, respondentFilter, searchTerm);
      setFilteredBlobItems(filteredAndSearchedDecisions);
    }
  }, [blobItems, decisionFilter, appealTypeFilter, appealSubTypeFilter, respondentFilter, searchTerm]);

  useEffect(() => {
    if (blobItems.length > 0) {
      const filterArrays = creatingFilterArraysForDecisons(filteredBlobItems);
      setAppealTypes(filterArrays[0]);
      setAppealSubTypes(filterArrays[1]);
      setDecisions(filterArrays[2]);
      setRespondents(filterArrays[3]);
    }
  }, [blobItems, filteredBlobItems]);

  useEffect(() => {
    //as a result of using the search while on, for example, the last page, the search result may fit in less pages and the below makes sure that the last page will be refreshed and correctly numbered
    if (
      currentPage > Math.ceil(filteredBlobItems.length / itemsInAPage) && 
      currentPage !== 1 && 
      filteredBlobItems.length !==0
    ) {
      setCurrentPage(Math.ceil(filteredBlobItems.length / itemsInAPage));
    } else {
      setCurrentBlobItems(renderWithPaging(filteredBlobItems, currentPage, itemsInAPage));
    }
  }, [filteredBlobItems, currentPage, itemsInAPage]);


  const childPageClicked = (page: number) => {
    setCurrentPage(page);
  };
  
  const onSearchBoxChange = (searchInput: string) => {
    setSearchTerm(searchInput);
  };

  const onAppealTypeFilterChange = (option: HTMLOptionElement) => {
    setAppealTypeFilter(option.value);
  };

  const onAppealSubTypeFilterChange = (option: HTMLOptionElement) => {
    setAppealSubTypeFilter(option.value);
  };

  const onDecisionFilterChange = (option: HTMLOptionElement) => {
    setDecisionFilter(option.value);
  };

  const onRespondentFilterChange = (option: HTMLOptionElement) => {
    setRespondentFilter(option.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setAppealTypeFilter('All');
    setAppealSubTypeFilter('All');
    setRespondentFilter('All');
    setDecisionFilter('All');
    setCurrentPage(1);
  }

  const renderLoader = () => {
    if (isFetchingData) 
      return <Loader loadingText={'getting decisions'} padding={''} />;
  };

  const renderNetworkErrorMessage = () => {
    return (
      <ErrorSimple
        message={
          'We have been unable to retrieve decisions at the moment. Please try later or contact us via the contact page'
        }
        padding={'0.5rem'}
        margin={''}
        icon={'bi bi-exclamation-square-fill'}
        iconFontSize={'4rem'}
        textFontSize={''}
      />
    );
  };

  const renderNoBlobsFoundMessage = () => {
    return (
      <PhaseBanner
        tag={'No Decisions Yet'}
        link={'/contact-us'}
        text1={
          'This is a new service so there may be a delay in decisions being published.'
        }
        text2={'if you believe this to be incorrect.'}
        linkText={'Contact us'}
      />
    );
  };

  const renderNoDecisionsFoundMessage = () => {
    return (
      <PhaseBanner
        tag={'No Decisions Found'}
        link={'/decisions'}
        text1={
          'No decisions found based on the search term.'
        }
        text2={'Please change the search term, or select Reset filters link in order to remove all filters and the search term.'}
        linkText={'Reset filters.'}
      />
    );
  };

  const renderDecisions = () => {
    return (
      <div className="d-flex flex-column">
        <PagingSg
          numberOfPages={Math.ceil(filteredBlobItems.length / itemsInAPage)}
          numberOfItems={filteredBlobItems.length}
          currentPage={currentPage}
          numberOfPageLinks={5}
          pageClicked={childPageClicked}
        />
        <DecisionsTable 
          data={currentBlobItems} 
          columns={DecisonsParentColumns} 
          renderSubComponent={renderSubComponent} 
          getRowCanExpand={() => true} 
        />
      </div>
    );
  };

  const renderDecisionsTableOrError = () => {
    if (networkError) return renderNetworkErrorMessage();
    else {
      if (noBlobs) return renderNoBlobsFoundMessage();
      else {
        if (currentBlobItems.length === 0 && !isFetchingData) return renderNoDecisionsFoundMessage();
        if (blobItems && currentBlobItems.length > 0) return renderDecisions();
      }
    }
  };

  return (
    <>
      <PageHeaderSg headerText={'Published Decisions'} />
      <p>When searching for decision information, you can use the drop down menus or type into the free text search box. The search is available to look for decisions based on any of the fields, including appeal type, appeal reason, property address and representative.</p>  
      <div className="d-flex flex-sm-column flex-md-row filter-row decisions-filter-row">
        <div className="filter">
          <SelectComponent 
            selectId={'appeal-type-filter'} 
            selectedValue={appealTypeFilter} 
            selectedDisabled={false} 
            selectLabelText={'Appeal Type'} 
            selectName={'Appeal Type Filter'}
            selectOptions={appealTypes}
            onChange={onAppealTypeFilterChange} 
          />
        </div>
        <div className="filter">
          <SelectComponent 
            selectId={'appeal-sub-type'} 
            selectedValue={appealSubTypeFilter} 
            selectedDisabled={appealTypeFilter !== "All" ? false : true} 
            selectLabelText={'Appeal Subtype'} 
            selectName={'Appeal Subtype Filter'}
            selectOptions={appealSubTypes}
            onChange={onAppealSubTypeFilterChange} 
          />
        </div>
        <div className="filter">
          <SelectComponent 
            selectId={'decision-filter'} 
            selectedValue={decisionFilter} 
            selectedDisabled={false} 
            selectLabelText={'Decision'} 
            selectName={'Decision Filter'}
            selectOptions={decisions} 
            onChange={onDecisionFilterChange} 
          />
        </div>
        <div className="filter">
          <SelectComponent 
            selectId={'respondent-filter'} 
            selectedValue={respondentFilter} 
            selectedDisabled={false} 
            selectLabelText={'Respondent'} 
            selectName={'Respondent Filter'}
            selectOptions={respondents} 
            onChange={onRespondentFilterChange} 
          />
        </div>
      </div>
      <div className="d-flex flex-sm-column flex-md-row search-row">
        <div className="search">
          <SearchAsYouTypeComponent 
            inputId={"search"} 
            inputValue={searchTerm}
            labelText={"Search all fields, e.g. case reference, decision, etc. (min 3 characters)"} 
            inputType={"text"} 
            placeholder={"Search as you type..."} 
            searchLabel={"search"} 
            onSearch={(e: string) => {
              onSearchBoxChange(e);
            }} 
          />
        </div>
        <div className=" clear-button">
          <PrimaryButton
            buttonText={'Clear All'}
            onClick={clearSearch} 
          />
        </div>
      </div>
      <div className="d-flex flex-sm-column flex-md-row search-row">
        <p className='nr-of-results'>Number of decisions: {filteredBlobItems.length}</p>
      </div>
      {renderLoader()}
      {renderDecisionsTableOrError()}
    </>
  );
};
export default Decisions;
