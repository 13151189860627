import { AppDataLocalTax } from '@scts/app-data';
import { PageHeaderSg, PrimaryButton } from '@scts-ui/component-library';
import { useTitleService } from '@scts/utilities';
import { useNavigate } from 'react-router-dom';

export const Home = () => {
  useTitleService('Home: Local Taxation Chamber');
  const nav = useNavigate();
  const startAppeal = () => {
    nav('/apply');
  };

  return (
    <>
      <PageHeaderSg headerText={'Local Taxation Chamber'} />

      <p>
        The Local Taxation Chamber is a new First-tier Tribunal chamber which
        will deal with council tax, council tax reduction, water charges and non
        domestic rates appeals after 1st April 2023. The current functions of
        the Valuation Appeal Panels and the Council Tax Reduction Review Panel
        will transfer to the new Local Taxation Chamber on 1st April 2023.
      </p>
      <p>The Local Taxation Chamber will hear the following type of appeals:</p>
      <div className="d-flex flex-column d-md-flex flex-md-row">
        <ul>
          {AppDataLocalTax &&
            AppDataLocalTax.appealTypesCt.items.map((item, index) => (
              <li key={index}>{item.title}</li>
            ))}

          {AppDataLocalTax &&
            AppDataLocalTax.appealTypesNdr.items.map((item, index) => (
              <li key={index}>{item.title}</li>
            ))}

          {AppDataLocalTax &&
            AppDataLocalTax.appealTypesWater.items.map((item, index) => (
              <li key={index}>{item.title}</li>
            ))}
        </ul>
      </div>

      <PrimaryButton
        onClick={() => startAppeal()}
        buttonText="Start your appeal now"
      />
    </>
  );
};

export default Home;
