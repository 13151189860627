import { AppDataLocalTax } from '@scts/app-data';
import { AccordionBsLists, PageHeaderSg } from '@scts-ui/component-library';
import { useTitleService } from '@scts/utilities';

const Reviews = () => {
  useTitleService('Reviewing and appealing decisions: Local Taxation Chamber');

  return (
    <>
      <PageHeaderSg headerText="Appealing and Reviewing Decisions" />

      <p>
        If you have received a decision from the Local Taxation Chamber, you may
        be entitled to submit an appeal or seek a review of the decision.
      </p>
      <p>
        Please visit the individual jurisdiction pages to find out more on the
        types of decisions that are subject to appeal or review. If you have an
        administrative complaint, or a complaint about a judicial member, please
        visit
        <a href="/contact-us"> the contact page.</a>
      </p>

      <AccordionBsLists reviewFaqs={AppDataLocalTax.reviewFaqs} />
    </>
  );
};

export default Reviews;
