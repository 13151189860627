import { ColumnDef } from '@tanstack/react-table';
import { LtcHearing } from '@scts/models';

export const HearingsParentColumns: ColumnDef<LtcHearing>[] = [
  {
    id: 'expander',
//Show dropdown in every table row
    header: () => <span className='visually-hidden'>Toggle expand button to expand or close table rows</span>,
    cell: (info) => {
      return info.row.getCanExpand() ? (

//Show drop down only in those rows that have data for their child table
    // accessorFn: row => `${!row.assessorReference && !row.propertyType && !row.netAnnualValue && !row.rateableValue && !row.effectiveDate ? 'true' : 'false'}`,
    // header: () => '',
    // cell: (info) => {
    //   return (info.row.getCanExpand() && info.getValue() === 'false') ? (

        <button
          {...{
            onClick: info.row.getToggleExpandedHandler(),
            style: { cursor: 'pointer', border: 'none', backgroundColor: 'transparent' },
            title:'Expand row button',
            'aria-label': 'Toggle to expand row for more information, or close it',
          }}
        >
          {info.row.getIsExpanded() ? 
          <span className="ds_accordion-item__indicator" 
            style={{
              height: '15px', 
              width: '15px', 
              position:'static', 
              color: '#0065bd',
              transition:'.3s'
          }}/> 
          : <span className="ds_accordion-item__indicator ds_accordion-item__indicator-clicked" 
          style={{
            height: '15px', 
            width: '15px', 
            position:'static', 
            color: '#0065bd',
            transition:'.3s',
            rotate: '-90deg'
          }}/>
        }
        </button>
      ) : (
        ''
      )
    },
  },
  {
    accessorFn: row => `${row.hearingDateAndTime ? 
      new Date(row.hearingDateAndTime).toLocaleDateString() + '\n' + new Date(row.hearingDateAndTime).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' }) : 
      'N/A'}`,
    header: 'Date/Time',
    cell: info => info.getValue(),
    footer: info => info.column.id,
  },
  {
    accessorFn: row => `${row.reference ? row.reference : 'N/A'}`,
    header: 'Case Reference',
    cell: info => info.getValue(),
    footer: info => info.column.id,
  },
  {
    accessorFn: row => `${row.appealType ? row.appealType : 'N/A'} ${row.appealSubtype ? '(' + row.appealSubtype + ')' : ''}`,
    header: 'Appeal Type',
    cell: info => info.renderValue(),
    footer: info => info.column.id,
  },
  {
    accessorFn: row => `${row.venue ? row.venue : 'N/A'}`,
    header: 'Venue',
    cell: info => info.renderValue(),
    footer: info => info.column.id,
  },
  {
    accessorFn: row => `${row.hearingType ? row.hearingType : 'N/A'}`,
    header: 'Hearing Type',
    cell: info => info.renderValue(),
    footer: info => info.column.id,
  },
  {
    accessorFn: row => `${row.appellant ? row.appellant : 'N/A'}`,
    header: 'Appellant',
    cell: info => info.renderValue(),
      footer: info => info.column.id,
  },
  {
    accessorFn: row => `${row.appellantRepresentative ? row.appellantRepresentative : 'N/A'}`,
    header: 'Representative',
    cell: info => info.renderValue(),
    footer: info => info.column.id,
  },
  {
    accessorFn: row => `${row.respondent ? row.respondent : 'N/A'}`,
    header: 'Respondent',
    cell: info => info.renderValue(),
    footer: info => info.column.id,
  }
];