import { Row } from '@tanstack/react-table';
import { FileBlob } from '@scts/models';

interface Props {
    row: Row<FileBlob>
}

export const DecisionsRow = (props: Props) => {    
    return (
        <tr>
            <td>{props.row.original.meta.AppealType ? props.row.original.meta.AppealType : 'N/A'}{props.row.original.meta.AppealSubType ? ` (${props.row.original.meta.AppealSubType})` : ''}</td>
            <td>{props.row.original.meta.AppealReason}</td>
            <td>{props.row.original.meta.Address ? props.row.original.meta.Address : 'N/A'}</td>
            <td>{props.row.original.meta.AppellantRepresentative ? props.row.original.meta.AppellantRepresentative : 'N/A'}</td>
        </tr>
    )
}
