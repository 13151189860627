import { AppDataLocalTax } from '@scts/app-data';
import { PageHeader, PageHeaderSg } from '@scts-ui/component-library';
import { useTitleService } from '@scts/utilities';

const TermsConditions = () => {
  useTitleService('Terms & Conditions: Local Taxation Chamber');

  return (
    <>
      <PageHeaderSg headerText={'Terms and Conditions'} />
      <PageHeader icon={''} detail={'Disclaimer'} />

      <pre>{AppDataLocalTax.terms.disclaimer}</pre>

      <PageHeader icon={''} detail={'Use of this site'} />

      <p>
        The First-tier Tribunal for Scotland (Local Taxation Chamber) seeks to
        ensure that the information published on its website is up to date and
        accurate. However, the information on the website does not constitute
        legal or professional advice and the Chamber cannot accept any liability
        for actions arising from its use.
      </p>
      <PageHeader icon={''} detail={'External links'} />

      <p>
        Links to external sites may open in a new window. The Chamber cannot be
        held responsible for the contents of any pages referenced by an external
        link.
      </p>
      <PageHeader icon={''} detail={'Copyright'} />
      <p>
        Material may be reproduced without formal permission or charge for
        personal or in-house use only. If in doubt or for further information,
        please refer to the{' '}
        <a href="https://www.scotcourts.gov.uk/meta/crown-copyright">
          {' '}
          Scottish Courts and Tribunals website.
        </a>
      </p>

      <PageHeader icon={''} detail={'Personal Data'} />
      <p>
        Any personal data collected through this website will be treated as
        confidential in line with the principles of the Data Protection Act
        1998.
      </p>
    </>
  );
};

export default TermsConditions;
