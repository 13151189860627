import React, { Fragment } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import { FileBlob, LtcExpandableTableProps } from '@scts/models';
import { DecisionsRow } from './decisions-row';

export const renderSubComponent = ({ row }: { row: Row<FileBlob> }) => {
  return (
    <pre style={{ fontSize: '10px' }}>
      <code>{JSON.stringify(row.original, null, 2)}</code>
    </pre>
  )
}

function DecisionsTable({
  data,
  columns,
  renderSubComponent,
  getRowCanExpand,
}: LtcExpandableTableProps<FileBlob>): JSX.Element {
  const table = useReactTable<FileBlob>({
    data,
    columns,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })
    
  return (
    <div className="table-responsive">
      <table className='ds_table decisions-table parent-table'>
        <caption>
          Decisions that have been published by the Local Taxation Chamber
        </caption>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <Fragment key={row.id}>
                <tr>
                  {/* first row is a normal row */}
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
                {row.getIsExpanded() && (
                  <tr>
                    {/* 2nd row is a custom 1 cell row */}
                    <td colSpan={row.getVisibleCells().length}>
                      <div className="p-2">
                        <div className="h-2 table-responsive">
                          <table className='ds_table decisions-table child-table'>
                            <thead>
                              <th>Appeal Type</th>
                              <th>Appeal Reason</th>
                              <th>Address</th>
                              <th>Representative</th>
                            </thead>
                            <tbody>
                              <DecisionsRow row={row} />
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
    // <div>{table.getRowModel().rows.length} Rows</div>
  )
}

export default DecisionsTable;