import React, { Fragment } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import { LtcExpandableTableProps, LtcHearing } from '@scts/models';
import { HearingsRow, HearingsSecondaryRow } from './hearings-row';

export const renderSubComponent = ({ row }: { row: Row<LtcHearing> }) => {
  return (
    <pre style={{ fontSize: '10px' }}>
      <code>{JSON.stringify(row.original, null, 2)}</code>
    </pre>
  )
}

function HearingsTable({
  data,
  columns,
  renderSubComponent,
  getRowCanExpand,
}: LtcExpandableTableProps<LtcHearing>): JSX.Element {
  const table = useReactTable<LtcHearing>({
    data,
    columns,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })

  const isNonDomesticRate = (i: string) => {
    if (i !== 'Non-Domestic Rates') {
      return false;
    } else {
      return true;
    }
  };
    
  return (
    <div className="p-2">
      <div className="h-2 table-responsive">
        <table className='ds_table hearings-table parent-table'>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <Fragment key={row.id}>
                  <tr>
                    {/* first row is a normal row */}
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      )
                    })}
                  </tr>
                  {row.getIsExpanded() && (
                    <tr>
                      {/* 2nd row is a custom 1 cell row */}
                      <td colSpan={row.getVisibleCells().length}>
                        <div className="p-2">
                          <div className="h-2 table-responsive">
                            <table className='ds_table hearings-table child-table'>
                            <thead>
                                <th>Address</th>
                              </thead>
                              <tbody>
                                <HearingsSecondaryRow row={row} />
                              </tbody>
                            </table>
                            {isNonDomesticRate(row.original.appealType) &&
                              <table className='ds_table hearings-table child-table'>
                                <thead>
                                  <th>Assessor Reference</th>
                                  <th>Property Type</th>
                                  <th>Net Annual Value</th>
                                  <th>Rateable Value</th>
                                  <th>Effective Date</th>
                                  <th>Appeal Date</th>
                                  <th>Appeal Reason</th>
                                </thead>
                                <tbody>
                                  <HearingsRow row={row} />
                                </tbody>
                              </table>
                            }
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
      {/* <div>{table.getRowModel().rows.length} Rows</div> */}
    </div>
  )
}

export default HearingsTable;