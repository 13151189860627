export const ProcessClose = () => {
  return (
    <>
      <span className=" d-none d-sm-block">Close appeal process </span>
      <span className=" d-block d-sm-none">Close process </span>
      <i className="bi bi-chevron-up"></i>
    </>
  );
};

export default ProcessClose;
