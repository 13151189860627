import { FileBlob, LtcHearing } from "@scts/models";
import {filterDecisions, filterHearings} from './filter';
import { searchDecisions, searchHearings } from "./search";
  
export const filterAndSearchDecisions = (
  blobItemsArray: FileBlob[],
  decisionFilter: string,
  appealTypeFilter: string,
  appealReasonFilter: string,
  respondentFilter: string,
  searchTerm: string) => {

    let currentBlobItemsArray: FileBlob[] = blobItemsArray;

    currentBlobItemsArray = filterDecisions(
      currentBlobItemsArray, 
      decisionFilter, 
      appealTypeFilter, 
      appealReasonFilter, 
      respondentFilter
    );
    
    if (searchTerm.length > 2) {
      currentBlobItemsArray = searchDecisions(currentBlobItemsArray, searchTerm);
    } 

    return currentBlobItemsArray;
};

export const filterAndSearchHearings = (
  hearingsArray: LtcHearing[],
  dateFilter: string,
  appealTypeFilter: string,
  respondentFilter: string,
  searchTerm: string) => {

    let currentHearingsArray: LtcHearing[] = hearingsArray;

    currentHearingsArray = filterHearings(currentHearingsArray, dateFilter, appealTypeFilter, respondentFilter);

    if (searchTerm.length > 2) {
      currentHearingsArray = searchHearings(currentHearingsArray, searchTerm);
    }

    return currentHearingsArray;
}; 