import { AppDataLocalTax } from '@scts/app-data';
import { AccordionBs, PageHeaderSg } from '@scts-ui/component-library';
import { useTitleService } from '@scts/utilities';

export const Faqs = () => {
  useTitleService('FAQs: Local Taxation Chamber');

  return (
    <>
      <PageHeaderSg headerText="General FAQs" />
      <p>Please view frequently asked questions below:</p>
      <AccordionBs items={AppDataLocalTax.faqs} index={0} />
    </>
  );
};

export default Faqs;
