import { FileBlob, LtcHearing } from "@scts/models";

export const searchDecisions = (arrayToSearch: FileBlob[], searchTerm: string) => {
  searchTerm =searchTerm.toLowerCase();

  const filteredDecisionsArray: FileBlob[] = arrayToSearch.filter((blob: FileBlob) => {
    if (
      blob.meta.Address?.toLowerCase().includes(searchTerm) ||
      (`${blob.meta.AppealType} (${blob.meta.AppealSubType?.toLowerCase()})`).toLowerCase().includes(searchTerm) ||
      blob.meta.AppealReason?.toLowerCase().includes(searchTerm) ||
      blob.meta.Appellant?.toLowerCase().includes(searchTerm) ||
      blob.meta.DecisionDate?.toLowerCase().includes(searchTerm) ||
      blob.meta.DecisionType?.toLowerCase().includes(searchTerm) ||
      blob.meta.Reference?.toLowerCase().includes(searchTerm) ||              
      blob.meta.Respondent?.toLowerCase().includes(searchTerm) ||
      blob.meta.AppellantRepresentative?.toLowerCase().includes(searchTerm)
      ) {
        return blob;
      }
  });
  return filteredDecisionsArray;
};

export const searchHearings = (arrayToSearch: LtcHearing[], searchTerm: string) => {
  searchTerm = searchTerm.toLowerCase();

  const filteredHearingsArray: LtcHearing[] = arrayToSearch.filter((hearing: LtcHearing) => {
    const hearingDateAndTime = hearing.hearingDateAndTime ? new Date(hearing.hearingDateAndTime).toLocaleDateString() : 'n/a';
    const effectiveDate = hearing.effectiveDate ? new Date(hearing.effectiveDate).toLocaleDateString() : 'n/a';
    const appealDate = hearing.appealDate ? new Date(hearing.appealDate).toLocaleDateString() : 'n/a';
    
    if (
      hearingDateAndTime.includes(searchTerm) ||
      String(hearing.address).toLowerCase().includes(searchTerm) ||
      hearing.reference.toLowerCase().includes(searchTerm) ||    
      (`${hearing.appealType} (${hearing.appealSubtype?.toLowerCase()})`).toLowerCase().includes(searchTerm) ||
      hearing.venue.toLowerCase().includes(searchTerm) ||
      hearing.hearingType.toLowerCase().includes(searchTerm) ||
      String(hearing.appellant).toLowerCase().includes(searchTerm) ||
      hearing.respondent.toLowerCase().includes(searchTerm) ||
      String(hearing.appellantRepresentative).toLowerCase().includes(searchTerm) ||
      String(hearing.assessorReference).toLowerCase().includes(searchTerm) ||
      String(hearing.propertyType).toLowerCase().includes(searchTerm) ||
      String(hearing.netAnnualValue).toLowerCase().includes(searchTerm) ||
      String(hearing.rateableValue).toLowerCase().includes(searchTerm) ||
      effectiveDate.includes(searchTerm) ||
      appealDate.includes(searchTerm) ||
      String(hearing.appealReason).toLowerCase().includes(searchTerm)
      ) {
        return hearing;
      }
  });
  
return filteredHearingsArray;
};