import { AppDataLocalTax } from '@scts/app-data';
import { PageHeaderSg } from '@scts-ui/component-library';
import { useTitleService } from '@scts/utilities';

export const ExistingAppeals = () => {
  useTitleService('Appeals before April 2023: Local Taxation Chamber');

  return (
    <>
      <PageHeaderSg headerText={'Appeals lodged before 1st April 2023'} />
      <p>
        Understand what happens to my EXISTING appeal after 1st April 2023? (An
        existing appeal is an Appeal lodged before 1st April 2023)
      </p>

      {AppDataLocalTax.existingAppealTypes.items.map((item, index) => (
        <div key={index} className="pb-3 pt-3">
          <h2>{item.title}</h2>
          <p>{item.body}</p>
        </div>
      ))}
    </>
  );
};

export default ExistingAppeals;
