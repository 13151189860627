import { Row } from '@tanstack/react-table';
import { LtcHearing } from '@scts/models';

interface Props {
    row: Row<LtcHearing>
}

export const HearingsRow = (props: Props) => {

    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 0,
      });
    return (
        <tr>
            <td>{props.row.original.assessorReference ? props.row.original.assessorReference : 'N/A'}</td>
            <td>{props.row.original.propertyType ? props.row.original.propertyType : 'N/A'}</td>
            <td>{props.row.original.netAnnualValue ? formatter.format(Number(props.row.original.netAnnualValue)) : 'N/A'}</td>
            <td>{props.row.original.rateableValue ? formatter.format(Number(props.row.original.rateableValue)) : 'N/A'}</td>
            <td>{props.row.original.effectiveDate ? new Date(props.row.original.effectiveDate).toLocaleDateString() : 'N/A'}</td>
            <td>{props.row.original.appealDate ? new Date(props.row.original.appealDate).toLocaleDateString() : 'N/A'}</td>
            <td>{props.row.original.appealReason ? props.row.original.appealReason : 'N/A'}</td>
        </tr> 
    );
};

export const HearingsSecondaryRow = (props: Props) => {

    return (
        <tr>
            <td>{props.row.original.address ? props.row.original.address : 'N/A'}</td>
        </tr> 
    );
};